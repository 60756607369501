export const formatDate = (dateString) => {
  if (!dateString) return 'N/A';

  if (typeof dateString === 'string') {
    const date = new Date(dateString);
    if (!isNaN(date.getTime())) {
      return date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
      });
    }
    // If parsing fails, it's likely the "Month Day, Year at Time" format
    const parsedDate = new Date(dateString.replace(' at ', ' '));
    if (!isNaN(parsedDate.getTime())) {
      return parsedDate.toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
      });
    }
  }

  // Handle Firestore Timestamp
  if (dateString && dateString.toDate && typeof dateString.toDate === 'function') {
    const date = dateString.toDate();
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    });
  }

  // If all parsing attempts fail, return the original value
  return dateString.toString();
};
