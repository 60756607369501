import React, { useState, useEffect } from "react";
import { getDoc, doc } from "firebase/firestore";
import { db } from "../../shared/firebase";
import {
  Box,
  IconButton,
  Typography,
  TextField,
  Menu,
  MenuItem,
  Slider,
  Alert,
  Badge,
  Avatar,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import MicIcon from "@mui/icons-material/Mic";
import StopIcon from "@mui/icons-material/Stop";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import CircleIcon from "@mui/icons-material/Circle";
import { colors } from "../lib/theme";
import { formatDate } from "../../shared/formatDate";
import RefreshLink from "../../shared/RefreshLink";
import ConfirmDialog from "../../shared/ConfirmDialog";

const TrackItem = ({
  track,
  isPlaying,
  playingTrackId,
  handlePlayPause,
  handlePause,
  currentTime,
  duration,
  handleSeek,
  user,
  userId,
  sharedWith,
  isRecording,
  recordingTrackId,
  openAudioSegmentsOnload,
  stopRecording,
  startRecording,
  editingTrackId,
  editingTrackTitle,
  handleEditTrack,
  handleSaveTrackTitle,
  handleCancelEdit,
  setEditingTrackTitle,
  handleDeleteTrack,
  albumId,
  showTrackControls = true,
  remainingTime,
  handleDeleteSegment,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [recordedByUser, setRecordedByUser] = useState(null);
  const [expanded, setExpanded] = useState(openAudioSegmentsOnload);
  const [segmentUsers, setSegmentUsers] = useState({});
  const canEditTrack =
    user && (user.uid === userId || sharedWith.includes(user.uid));
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [segmentToDelete, setSegmentToDelete] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    const fetchRecordedByUser = async () => {
      if (track.audioSegments && track.audioSegments.length) {
        const recordedByUid =
          track.audioSegments[track.audioSegments.length - 1].recordedBy;
        if (recordedByUid) {
          try {
            const userDoc = await getDoc(doc(db, "users", recordedByUid));
            if (userDoc.exists()) {
              const userData = userDoc.data();
              setRecordedByUser(userData);
            }
          } catch (error) {
            console.error("Error fetching user data:", error);
          }
        }
      }
    };

    fetchRecordedByUser();
  }, [track.audioSegments]);

  useEffect(() => {
    const fetchSegmentUsers = async () => {
      if (track.audioSegments && track.audioSegments.length) {
        const userPromises = track.audioSegments.map(async (segment) => {
          if (segment.recordedBy) {
            try {
              const userDoc = await getDoc(
                doc(db, "users", segment.recordedBy)
              );
              if (userDoc.exists()) {
                return { [segment.id]: userDoc.data() };
              }
            } catch (error) {
              console.error("Error fetching user data:", error);
            }
          }
          return { [segment.id]: null };
        });

        const users = await Promise.all(userPromises);
        setSegmentUsers(Object.assign({}, ...users));
      }
    };

    fetchSegmentUsers();
  }, [track.audioSegments]);

  useEffect(() => {
    if (
      isPlaying &&
      track.audioSegments &&
      track.audioSegments.some((segment) => segment.id === playingTrackId)
    ) {
      setExpanded(true);
    }
  }, [isPlaying, playingTrackId, track.audioSegments]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60) || 0;
    const seconds = Math.floor(time % 60) || 0;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const isTrackPlaying =
    isPlaying &&
    track.audioSegments &&
    track.audioSegments.some((segment) => segment.id === playingTrackId);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleDeleteSegmentClick = (trackId, segmentId) => {
    setSegmentToDelete({ trackId, segmentId });
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    if (segmentToDelete) {
      handleDeleteSegment(segmentToDelete.trackId, segmentToDelete.segmentId);
    }
    setDeleteDialogOpen(false);
    setSegmentToDelete(null);
  };

  const handleCancelDelete = () => {
    setDeleteDialogOpen(false);
    setSegmentToDelete(null);
  };

  const handlePlayPauseClick = (track, index) => {
    handlePlayPause(track, index);
  };

  return (
    <>
      {isUploading && (
        <Alert severity="info" sx={{ mb: 1 }}>
          Uploading track, please wait...
        </Alert>
      )}
      {isRecording && recordingTrackId === track.id && remainingTime <= 120 && (
        <Alert severity="warning" sx={{ mb: 1 }}>
          Recording will end in {remainingTime} seconds
        </Alert>
      )}
      <Box
        display="flex"
        alignItems="center"
        sx={{
          padding: 0,
          mb: 0,
          transition: "background-color 0.3s",
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.1)",
          },
        }}
      >
        {/* Play/Pause button */}
        <Box sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center"
        }}>
          <>
            {track.audioSegments && track.audioSegments.length > 0 && (
              <IconButton
                onClick={() => handlePlayPauseClick(track, 0)}
                sx={{
                  color: colors.white,
                  padding: { xs: 0 },
                  '@media (min-width:736px)': {
                    padding: 1,
                  },
                }}
              >
                {isTrackPlaying ? <PauseIcon /> : <PlayArrowIcon />}
              </IconButton>
            )}
            <IconButton
              onClick={() =>
                isRecording ? stopRecording() : startRecording(track.id)
              }
              sx={{
                color: colors.white,
              }}
            >
              {isRecording && recordingTrackId === track.id ? (
                <Box
                  sx={{
                    padding: "8px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <StopIcon sx={{ color: "white", fontSize: "1.2rem" }} />
                </Box>
              ) : (
                <Box
                  sx={{
                    backgroundColor: "red",
                    borderRadius: "50%",
                    padding: "8px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "&:hover": {
                      backgroundColor: "darkred",
                    },
                  }}
                >
                  <MicIcon sx={{ color: "white", fontSize: "1.2rem" }} />
                </Box>
              )}
            </IconButton>
          </>
        </Box>

        {/* Track title and editing */}
        <Box flexGrow={1} sx={{ p: 2 }}>
          {editingTrackId === track.id ? (
            <Box display="flex" alignItems="center">
              <TextField
                value={editingTrackTitle}
                onChange={(e) => setEditingTrackTitle(e.target.value)}
                size="small"
                sx={{ mr: 1, minWidth: 350 }}
              />
              <IconButton
                onClick={handleSaveTrackTitle}
                sx={{ color: colors.white }}
              >
                <CheckIcon />
              </IconButton>
              <IconButton
                onClick={handleCancelEdit}
                sx={{ color: colors.white }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          ) : (
            <Typography sx={{ color: colors.white, minWidth: 235 }}>
              <RefreshLink to={`/album/${albumId}/track/${track.id}`}>
                {track.title}
              </RefreshLink>
              {isRecording && recordingTrackId === track.id && (
                <CircleIcon
                  sx={{ color: "red", ml: 1, width: 12, height: 12 }}
                />
              )}
            </Typography>
          )}
          {track.updatedAt && (
            <Typography
              variant="caption"
              sx={{
                color: colors.lightYellow,
                display: "flex",
                alignItems: "center",
              }}
            >
              {track.audioSegments && track.audioSegments.length > 0 && (
                <>
                  <span style={{ minWidth: "130px" }}>
                    Updated {formatDate(track.updatedAt)}
                  </span>
                  {recordedByUser && (
                    <>
                      {recordedByUser.profilePhotoURL && (
                        <Avatar
                          src={recordedByUser.profilePhotoURL}
                          alt={
                            recordedByUser.firstName ||
                            recordedByUser.email ||
                            "Unknown"
                          }
                          sx={{ width: 32, height: 32, ml: 1, mr: 0.5 }}
                        />
                      )}
                      <div style={{ marginLeft: "4px", marginRight: "4px" }}>
                        By
                      </div>
                      <RefreshLink
                        to={`/user/${recordedByUser.userId || ""}`}
                        sx={{ fontWeight: "bold", mx: "4px" }}
                      >
                        {`${
                          recordedByUser.firstName ||
                          recordedByUser.email ||
                          "Unknown"
                        }`}
                      </RefreshLink>
                    </>
                  )}
                </>
              )}
            </Typography>
          )}
        </Box>

        {/* Action buttons */}
        {canEditTrack && (
          <Box
            sx={{
              minWidth: { xs: 50, sm: 96 },
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: { xs: "center", sm: "flex-end" },
              justifyContent: { xs: "flex-end", sm: "flex-end" },
            }}
          >
            {/* Add expand/collapse button with badge */}
            {track.audioSegments && track.audioSegments.length > 0 && (
              <Badge
                badgeContent={track.audioSegments.length}
                color="primary"
                sx={{
                  "& .MuiBadge-badge": {
                    right: "6px",
                    top: "10px",
                    border: `2px solid ${colors.gold}`,
                    padding: "0 4px",
                  },
                }}
              >
                <IconButton
                  onClick={toggleExpand}
                  sx={{
                    color: colors.white,
                    // Responsive size adjustment
                    width: { xs: 32, sm: 40 },
                    height: { xs: 32, sm: 40 },
                  }}
                >
                  {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Badge>
            )}
            {showTrackControls && (
              <>
                <IconButton
                  onClick={handleMenuOpen}
                  sx={{
                    color: colors.white,
                    // Responsive size adjustment
                    width: { xs: 32, sm: 40 },
                    height: { xs: 32, sm: 40 },
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
              </>
            )}
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem
                onClick={() => {
                  handleEditTrack(track.id, track.title);
                  handleMenuClose();
                }}
              >
                <EditIcon sx={{ mr: 1 }} />
                Rename
              </MenuItem>
              {user &&
                track.audioSegments &&
                track.audioSegments.some(
                  (segment) => segment.recordedBy === user.uid
                ) && (
                  <MenuItem
                    onClick={() => {
                      handleDeleteSegmentClick(
                        track.id,
                        track.audioSegments[0].id
                      );
                      handleMenuClose();
                    }}
                  >
                    <DeleteIcon sx={{ mr: 1 }} />
                    Delete My Recording
                  </MenuItem>
                )}
              <MenuItem
                onClick={() => {
                  handleDeleteTrack(track.id);
                  handleMenuClose();
                }}
              >
                <DeleteIcon sx={{ mr: 1 }} />
                Delete Track
              </MenuItem>
            </Menu>
          </Box>
        )}
      </Box>

      {/* Audio segments list and slider */}
      {(expanded ||
        ((isPlaying || playingTrackId) &&
          track.audioSegments &&
          track.audioSegments.some(
            (segment) => segment.id === playingTrackId
          ))) &&
      track.audioSegments &&
      track.audioSegments.length ? (
        <Box
          sx={{
            ml: 2,
            mt: 1,
            py: 0.25,
            px: 1,
            backgroundColor: colors.darkNavy,
          }}
        >
          {track.audioSegments.map((segment, index) => (
            <Box
              key={segment.id}
              sx={{
                mt: 1,
                mb: 1,
                p: 1,
                transition: "background-color 0.3s",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.05)",
                },
              }}
            >
              <Box display="flex" alignItems="center">
                <IconButton
                  onClick={() => handlePlayPauseClick(track, index)}
                  sx={{ color: colors.white, mr: 1 }}
                >
                  {isPlaying && playingTrackId === segment.id ? (
                    <PauseIcon />
                  ) : (
                    <PlayArrowIcon />
                  )}
                </IconButton>
                <Box
                  sx={{
                    fontSize: "0.9rem",
                    flexGrow: 1,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {segmentUsers[segment.id] ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <Box display="flex" alignItems="center">
                        <Avatar
                          src={segmentUsers[segment.id].profilePhotoURL}
                          alt={
                            segmentUsers[segment.id].firstName ||
                            segmentUsers[segment.id].email ||
                            "Unknown"
                          }
                          sx={{ width: 32, height: 32, mr: 0.5 }}
                        />
                        <RefreshLink
                          to={`/user/${segmentUsers[segment.id].userId}`}
                          sx={{ fontWeight: "bold", mx: "4px" }}
                        >
                          {segmentUsers[segment.id].firstName ||
                            segmentUsers[segment.id].email ||
                            "Unknown"}
                        </RefreshLink>
                      </Box>
                      <Box sx={{ fontSize: "0.8rem", mt: 1 }}>
                        on {formatDate(segment.updatedAt)}
                      </Box>
                    </div>
                  ) : (
                    `Recording ${index + 1}: ${formatDate(segment.updatedAt)}`
                  )}
                </Box>
                {user && user.uid === segment.recordedBy && (
                  <IconButton
                    onClick={() =>
                      handleDeleteSegmentClick(track.id, segment.id)
                    }
                    sx={{ color: colors.white }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>
              {playingTrackId === segment.id && (
                <Box sx={{ ml: 6, mt: 1 }}>
                  <Slider
                    value={currentTime}
                    min={0}
                    max={duration}
                    onChange={(e, value) => handleSeek(value)}
                    aria-labelledby="audio-slider"
                    sx={{ color: colors.gold }}
                  />
                </Box>
              )}
            </Box>
          ))}
        </Box>
      ) : (
        isTrackPlaying && (
          <Box sx={{ ml: 6, mt: 1 }}>
            <Slider
              value={currentTime}
              min={0}
              max={duration}
              onChange={(e, value) => handleSeek(value)}
              aria-labelledby="audio-slider"
              sx={{ color: colors.gold }}
            />
          </Box>
        )
      )}

      <ConfirmDialog
        open={deleteDialogOpen}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        title="Delete Your Recording"
        content="Are you sure you want to delete this recording? This action cannot be undone."
        confirmText="Delete"
      />
    </>
  );
};

export default TrackItem;
